import React, { Component } from 'react';

import {
    Button,
} from 'antd';

import vklogo from './img/vklogo.png';

class AboutModal extends Component {
    render() {
        const { visible, onClose } = this.props;
 
        return (
            <div 
                className={`
                    fixed top-0 bottom-0 left-0 right-0 z-10 flex justify-center items-center
                    ${visible ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-500
                `}
                style={{ background: 'rgba(37,32,29,0.9)' }}
            >
                <div className="relative max-w-lg p-4 sm:p-0">
                    <div className="text-lg tracking-wide">
                        <img className="h-12 mb-8" src="logo.svg" alt="Velokarte"></img>

                        <div className="mb-8">
                            <p>
                                Velokarte ir rīks, ar kura palīdzību var apskatīt Latvijas pilsētu veloinfrastruktūru. Šis rīks nodrošina datus, kas, cerams, ietekmēs valsts politiku attiecībā uz riteņbraukšanu.
                            </p>

                            <p>
                                Rīks izmanto iegūtos datus no <a className="underline" href="https://www.openstreetmap.org/" title="OpenStreetMap" target="_blank" rel="noopener noreferrer">OpenStreetMap (OSM)</a> - atvērtās ģeotelpisko datu kartēšanas platformas. Aicinām arī jūs veikt savu ieguldījumu šīs platformas attīstībā pievienojot informāciju par savas pilsētas ģeogrāfiskajiem un ģeotelpiskajiem datiem.
                            </p>
                        </div>

                        <Button type="primary" size="large" onClick={onClose}>
                            Aizvērt
                        </Button>

                        <div className="mt-16">
                            <div className="flex items-start justify-between">
                                <div className="w-8/12 flex h-10 flex-col sm:flex-row">
                                    <a
                                        className="h-full mr-4"
                                        href="https://www.veloklubs.lv/"
                                        arget="_BLANK" rel="noopener noreferrer"
                                    >
                                        <img className="h-full" src={vklogo} alt="Veloklubs.lv logo" />
                                    </a>
                                
                                </div>
                                <div className="w-4/12 flex flex-col">
                                    <a className="underline" href="https://www.veloklubs.lv/velokarte/" title="Velokarte.lv pamācību lapa" target="_blank" rel="noopener noreferrer">
                                        Pamācības
                                    </a>
                                    <a className="underline" href="mailto:admin@veloklubs.lv" target="_blank" rel="noopener noreferrer">
                                        Sazināties
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutModal;