import React, { Component } from 'react';

import {
    Button,
    Checkbox,
    Modal
} from 'antd';

class EditModal extends Component {
    render() {
        return (
            <Modal
                title="Rediģēt iekš OSM"
                visible={this.props.visible}
                onCancel={this.props.onClose}
                showCancel={false}
                footer={[
                    <Button key="2" type="text">
                        <a
                            className="hover:text-white"
                            target="_BLANK" rel="noopener noreferrer"
                            href={'https://www.veloklubs.lv/velokarte/'}
                        >
                            Skatīt pamācības
                        </a>
                    </Button>,
                    <Button key="3" type="primary">
                        <a
                            className="hover:text-white"
                            target="_BLANK" rel="noopener noreferrer"
                            href={this.props.getOsmUrl()}
                        >
                            Rediģēt iekš OSM
                        </a>
                    </Button>
                ]}
                centered={true}
                maskClosable={true}
            >
                <div>
                    <p>
                        Pateicamies par ieguldījumu Latvijas pilsētu riteņbraukšanas karšu uzlabošanā!
                    </p>
                    <p className="mb-8">
                        Velokarte.lv izmanto OpenStreetMap (OSM) integrāciju - lielāko atvērto karšu datu bāzi pasaulē. OSM rediģēšanas saskarne no sākuma var šķist drausmīga un nesaprotam. Lai palīdzētu, esam sagatavojuši <a className="underline" href="https://www.veloklubs.lv/velokarte" title="Veloklubs.lv - viss par velosipēdiem" target= "_blank" rel="noopener noreferrer">pamācību materiālus</a>. Ja pamācību materiālos neatradāt sev interesējošo informāciju, lūdzu, <a className="underline" href="mailto:admin@veloklubs.lv">sazinieties ar mums</a>.
                    </p>

                    <Checkbox onChange={this.props.onCheckboxChange}>
                        Turpmāk nerādīt šo paziņojumu.
                    </Checkbox>
                </div>
            </Modal>
        )
    }
}

export default EditModal;