export const osmi18n = {
    // Velo stāvvieta
    covered: 'Pārsegs',
    access: 'Piekļuve',
    capacity: 'Ietilpība',
    cyclestreets_id: '',
    maxstay: 'Maksimālais uzturēšanās laiks',
    surveillance: 'Video novērošana',
    supervised: 'Apsardze',
    lit: 'Apgaismots',
    bicycle_parking: 'Veids',
    
    // Velo stāvvietu veidi
    stands: 'U veida',
    wall_loops: 'Sienas cilpas',
    rack: 'Režģis',
    wave: 'Viļņveida',
    ground_slots: 'Bedrītes zemē',
    wide_stands: 'Platais U veids',
    anchors: 'Enkuri',
    shed: 'Būdiņa',
    bollard: '',
    lockers: 'Skapji',
    building: 'Ēka',
    informal: 'Informatīva',
    streetpod: '',
    tree: 'Koks',
    rope: 'Aukla',
    'two-tier': '',
    floor: '',
    handlebar_holder: '',

    // Dalīšanās un īrei paredzēti velosipēdu parki
    ref: 'Atsauce',
    description: 'Apraksts',
    'payment:cash': 'Samaksas veids: skaidra nauda',
    'payment:credit_cards': 'Samaksas veids: kredītkarte',
    'payment:debit_cards': 'Samaksas veids: debetkarte',
    'payment:mobile_app': 'Samaksas veids: mobilā lietotne',
    operator: 'Operators',
    'operator:type': 'Operatora veids',

    // Dalīšanās operatora veidi
    government: 'Valdības',
    religious: 'Reliģiskais',
    ngo: 'NVO',
    community: 'Kopienas',
    consortium: 'Konsorcija',
    cooperative: 'Kooperatīva',

    // Velo veikali
    repair: 'Remonts',
    second_hand: 'Lietoti produkti',
    phone: 'Telefons',
    'phone:2': 'Telefons 2',
    'phone:3': 'Telefons 3',
    level: 'Stāvs',
    start_date: 'Kopš',
    'service:bicycle:chaintool': 'Ķēdes atslēga (chaintool)',
    'service:bicycle:repair': 'Remonts',
    'service:bicycle:rental': 'Noma',
    'service:bicycle:pump': 'Pumpis',
    'service:bicycle:diy': 'DIY',
    'service:bicycle:cleaning': 'Tīrīšana',
    'service:bicycle:second_hand': 'Lietoti produkti',
    'service:bicycle:charging': 'Uzlāde',
    'service:bicycle:retail': 'Velo pārdošana',
    'service:bicycle:parts': 'Detaļu pārdošana',
    'service:bicycle:tools': 'Pieejami remonta rīki',

    //////////////////////////

    // Vispārīgi
    website: 'Mājas lapa',
    opening_hours: 'Darba laiks',
    note: 'Piezīme',
    'note:pt': 'Piezīme',
    email: 'E-pasts',
    wheelchair: 'Ratiņkrēsls',
    yes: 'Jā',
    no: 'Nē',
    unknown: 'Nezināms',
    free: 'Bezmaksas',
    fee: 'Maksas',
    only: 'Tikai',
    tyres: 'Riepas',
    public: 'Publisks',
    private: 'Privāts',
    limited: 'Ierobežots',
    designated: '',
    permissive: 'Atļaujošs',
    customers: 'Klienti',
    'addr:street': 'Iela',
    'addr:housenumber': 'Mājas numurs',
    
    // Pielāgoti iekšēji tagi (ne no OSM!)
    'ciclomapa:address': 'Adrese',

    //////////////////////////

    // Ignorētie tagi
    'id':  null,
    'amenity':  null,
    'name': null,
    'name:pt': null,
    'source': null,
    'shop': null,
    'alt_name': null,
    'addr:housename': null,
    'addr:door': null,
    'addr:postcode': null,
    'addr:unit': null,
    'addr:city': null,
    'addr:state': null,
    'addr:country': null,
    'addr:suburb': null,
    'addr:room': null,
    'internet_access': null,
    'internet_access:key': null,
    'internet_access:ssid': null,
    'pt:bicycle_parking': null,
    'bicycle_parking:pt': null,
    'survey:date': null,
    'disused:amenity': null,
    'network': null,
}